import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs/operators';
import { AlertModalService } from 'src/app/core/services/alert-modal.service';
import { HttpService } from 'src/app/core/services/http.service';
import { SpinnerService } from 'src/app/core/services/spinner.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { ModalRequestAccessConfirmation } from 'src/app/shared/components/modal-request-access/modal-disapprove-reason/modal-disapprove-reason.component';
import { ModalComponent } from 'src/app/shared/components/modal/modal.component';
import { AccessRequestStatusEnum } from 'src/app/shared/enums/access-request-status-enum';
import { ToastTypeEnum } from 'src/app/shared/enums/toast-type-enum';
import { Base64ToBlob } from 'src/app/shared/utils/base64ToBlob';
import * as FileSaver from 'file-saver';

@Component({
  selector: 'rastreabilidade-edit-access-requester',
  templateUrl: './edit-access-requester.component.html',
  styleUrls: ['./edit-access-requester.component.scss'],
})
export class EditAccessRequesterComponent implements OnInit {
  statusRequest: 'Aprovado' | 'Pendente' | 'Reprovado' | 'Em análise' = null;
  dateStatusRequest: 'Data Aprovado' | 'Data Reprovado';
  labelDateStatus: string = null;
  labelStatus: string = null;
  requester: any = null;
  requesterWithStatus: any;
  isCompanyBrazilian: boolean = false;
  validateForm: boolean = false;
  id: string = null;
  accessRequestStatus: boolean = false;
  isLoading = true;
  companyCategoryName: string = '';
  @ViewChild('modal') private modal: ModalComponent;
  disabled = true;

  base64File: string = '';
  selectedFile: File = null;
  fileName: string = '';
  fileExtension: string = '';
  fileSize: number = 0;
  showBtn: boolean = true;
  fileMessage: string = '';

  isAprovedOrRepdroved: boolean = true;

  fbRequestInformation = this.fb.group({
    id: [null],
    reference_information: [false],
    bond_letter: [false],
  });
  accept = false;

  registerForm = this.fb.group({
    id: [''],
    name: ['', Validators.required],
    cpf_passport: ['', Validators.required],
    corporative_email: ['', Validators.required],
    birth_date: ['', Validators.required],
    company_name: ['', Validators.required],
    role: ['', Validators.required],
    company_category: ['', Validators.required],
    company_category_name: ['', Validators.required],
    corporative_cellphone: ['', Validators.required],
    company_website: [''],
    corporative_telephone: ['', Validators.required],
    company_address: ['', Validators.required],
    city: ['', Validators.required],
    country: ['', Validators.required],
    zip_code: ['', Validators.required],
    name_complement: ['', Validators.required],
    corporative_telephone_complement: ['', Validators.required],
    corporative_email_complement: ['', Validators.required],
    cnpj: [''],
    accept: [true],
    locale: [''],
    complement_data_id: [''],
    company_bond_file_name: [''],
    company_bond_file_extension: [''],
    company_bond_file_size: [''],
    company_bond_file: [null],
    bondLetter: this.accept,
  });

  constructor(
    private services: HttpService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private toast: ToastService,
    private alertService: AlertModalService,
    private spinnerService: SpinnerService,
    private router: Router,
    private translateService: TranslateService
  ) {}

  disableAdditionalInformation = () =>
    !(
      this.fbRequestInformation.controls.reference_information ||
      this.fbRequestInformation.controls.bond_letter
    );

  disableApprove = () =>
    this.statusRequest === 'Aprovado' || this.statusRequest === 'Reprovado';

  disableDisaApprove = () =>
    this.statusRequest === 'Aprovado' || this.statusRequest === 'Reprovado';

  dataApprovedDisapproved = () => {
    if (this.requester?.status === 'aprovado') {
      this.translateService
        .get('manage_acccess_request.edit.approval_date')
        .subscribe((event) => {
          console.log('aprovado', this, this.labelDateStatus, event);
          this.labelDateStatus = event;
        });
    } else {
      this.translateService
        .get('manage_acccess_request.edit.disapproval_date')
        .subscribe((event) => {
          console.log('reprovado', this, this.labelDateStatus, event);
          this.labelDateStatus = event;
        });
    }
  };

  statusApprovedRepproved = () => {
    if (this.requester?.status === 'aprovado') {
      this.translateService
        .get('REPORTS.GENERIC.STATUS.APPROVED')
        .subscribe((event) => {
          console.log('aprovado', this, this.labelStatus, event);
          this.labelStatus = event;
        });
    } else {
      this.translateService
        .get('REPORTS.GENERIC.STATUS.DISAPPROVED')
        .subscribe((event) => {
          console.log('reprovado', this, this.labelStatus, event);
          this.labelStatus = event;
        });
    }
  };

  ngOnInit(): void {
    this.route.params.subscribe((param) => {
      this.id = param.id;
      this.getRequester(this.id);
      this.requesterWithStatus = this.requester;
    });
    this.fbRequestInformation.controls.id.setValue(this.id);

    this.changeAdditionalValues();
  }
  setValuesForm(): void {
    this.registerForm.controls.name.setValue(this.requester?.name);
    this.registerForm.controls.cpf_passport.setValue(
      this.requester?.cpf_passport
    );
    this.registerForm.controls.cpf_passport.disable();
    this.registerForm.controls.name.disable();
    this.registerForm.controls.birth_date.disable();
    this.registerForm.controls.corporative_email.setValue(
      this.requester?.corporative_email
    );
    this.registerForm.controls.birth_date.patchValue(
      this.formatDate(this.requester?.birth_date)
    );
    this.registerForm.controls.company_name.setValue(
      this.requester?.company_name
    );
    this.registerForm.controls.role.setValue(this.requester?.role);
    this.registerForm.controls.company_category.setValue(
      this.requester?.company_category
    );
    this.registerForm.controls.company_category_name.setValue(
      this.requester?.company_category_name
    );
    this.registerForm.controls.corporative_cellphone.setValue(
      '+' + this.requester?.corporative_cellphone
    );
    this.registerForm.controls.company_website.setValue(
      this.requester?.company_website
    );

    this.registerForm.controls.corporative_telephone.setValue(
      '+' + this.requester?.corporative_telephone
    );

    this.registerForm.controls.company_address.setValue(
      this.requester?.company_address
    );
    this.registerForm.controls.city.setValue(this.requester?.city);
    this.registerForm.controls.country.setValue(this.requester?.country);
    this.registerForm.controls.zip_code.setValue(this.requester?.zip_code);
    this.registerForm.controls.name_complement.setValue(
      this.requester?.name_complement
    );
    this.registerForm.controls.corporative_telephone_complement.setValue(
      this.requester?.corporative_telephone_complement
    );
    this.registerForm.controls.corporative_email_complement.setValue(
      this.requester?.corporative_email_complement
    );

    if (!!this.requester?.company_bond_file_name) {
      this.fileName = this.requester?.company_bond_file_name;
    }
  }

  private formatDate(date): string {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) {
      month = '0' + month;
    }
    if (day.length < 2) {
      day = '0' + day;
    }
    return [year, month, day].join('-');
  }

  getRequester(id: string): void {
    this.spinnerService.show();
    this.services.getById('Access/request', id).subscribe((res) => {
      this.requester = res.data;

      this.setValuesForm();
      if (this.requester.cnpj) {
        this.isCompanyBrazilian = true;
      }

      this.statusRequest = this.requester?.status;

      this.isAprovedOrRepdroved =
        this.requester?.status === 'pendente' ||
        this.requester?.status === 'em análise';

      const locale = res?.data?.isForeign ? 'en' : 'pt-br';
      this.getCategoryById(res?.data?.company_category, locale);

      if (
        res.data.status === AccessRequestStatusEnum.APPROVED ||
        res.data.status === AccessRequestStatusEnum.DISAPPROVED
      ) {
        this.accessRequestStatus = true;
      }

      this.spinnerService.hide();

      this.dataApprovedDisapproved();
      this.statusApprovedRepproved();
    });
  }

  getCategoryById(id: string, locale: string): void {
    this.services
      .get(`Company/categoryCompany/${id}?locale=${locale}`)
      .subscribe((res) => {
        this.companyCategoryName = res?.data?.description;
      });
  }

  changeAdditionalValues(): void {
    this.fbRequestInformation.valueChanges.subscribe((checked) => {
      this.validateForm = !!(
        checked.reference_information || checked.bond_letter
      );
    });
  }

  async submitAdditionalInformation(): Promise<void> {
    this.spinnerService.show();
    const form = this.fbRequestInformation.value;
    form.id = this.id;
    this.spinnerService.show();
    this.services.put('Access/requestAdditionalData', form, this.id).subscribe(
      (res) => {
        this.getRequester(this.id);
        this.isLoading = false;
        this.fbRequestInformation.controls.bond_letter.setValue(false);
        this.fbRequestInformation.controls.reference_information.setValue(
          false
        );
        this.spinnerService.hide();
        this.alertService.showResponseModal('success', res.data);
      },
      (err) => {
        this.translateService
          .get(`generic.toast.title.error`)
          .subscribe((msg) => {
            this.toast.showToast(ToastTypeEnum.DANGER, msg, err);
            this.spinnerService.hide();
          });
      }
    );
  }

  async disapprove(): Promise<void> {
    this.translateService
      .get([
        'manage_acccess_request.edit.registration_disapproval_confirmation',
        'manage_acccess_request.edit.disapprove_this_registration',
        'manage_acccess_request.edit.justification',
        'REPORTS.GENERIC.STATUS.DISAPPROVED',
        'REPORTS.GENERIC.STATUS_REQUEST',
      ])
      .subscribe(async (msg) => {
        const registrationDisapprovalConfirmation =
          msg[
            'manage_acccess_request.edit.registration_disapproval_confirmation'
          ];
        const disapproveThisRegistration =
          msg['manage_acccess_request.edit.disapprove_this_registration'];
        const justification = msg['manage_acccess_request.edit.justification'];
        const titleHistory = msg['REPORTS.GENERIC.STATUS_REQUEST'];
        const messageHistory = msg['REPORTS.GENERIC.STATUS.DISAPPROVED'];
        await this.alertService
          .showDisapproveReasonModal(disapproveThisRegistration)
          .pipe(
            map((data: ModalRequestAccessConfirmation) => {
              if (data.success) {
                this.spinnerService.show();

                this.services
                  .putWithParams(
                    'Access/requestDisapproved',
                    {
                      reason_failure: data.reasonFailure,
                    },
                    this.id
                  )
                  .subscribe(
                    async (res) => {
                      this.saveHistory(this.id, titleHistory, messageHistory);

                      this.isLoading = false;
                      this.spinnerService.hide();
                      await this.alertService
                        .showResponseModal('danger', res.data)
                        .pipe(
                          map((confirmed) => {
                            if (confirmed) {
                              this.goToMainManageAccessRequest();
                            }
                          })
                        )
                        .toPromise();
                    },
                    (err) => {
                      this.translateService
                        .get(`generic.toast.title.error`)
                        .subscribe((msg): void => {
                          this.toast.showToast(ToastTypeEnum.DANGER, msg, err);
                          this.isLoading = false;
                          this.spinnerService.hide();
                        });
                    }
                  );
              }
            })
          )
          .toPromise();
      });
  }

  public saveHistory(
    id: string,
    titleHistory: string,
    messageHistory: string
  ): void {
    const form = {
      requestId: id,
      title: titleHistory,
      description: messageHistory,
    };
    this.services
      .post(`Historic/SaveHistoryForUser/${this.id}`, form)
      .subscribe();
  }

  async approve(): Promise<void> {
    this.translateService
      .get([
        'MANAGE_ACCCESS_REQUEST.EDIT.MESSAGE_APPROVE',
        'manage_acccess_request.edit.registration_approval_confirmation',
        'REPORTS.GENERIC.STATUS.APPROVED',
        'REPORTS.GENERIC.STATUS_REQUEST',
      ])
      .subscribe(async (msg) => {
        const messageApprove =
          msg['MANAGE_ACCCESS_REQUEST.EDIT.MESSAGE_APPROVE'];
        const registrationApprovalConfirmation =
          msg['manage_acccess_request.edit.registration_approval_confirmation'];
        const titleHistory = msg['REPORTS.GENERIC.STATUS_REQUEST'];
        const messageHistory = msg['REPORTS.GENERIC.STATUS.APPROVED'];
        await this.alertService
          .showDynamicModal(
            'success',
            registrationApprovalConfirmation,
            messageApprove
          )
          .pipe(
            map((confirmed) => {
              if (confirmed) {
                this.spinnerService.show();

                this.services.putId('Access/requestApprove', this.id).subscribe(
                  async (res) => {
                    this.getRequester(this.id);
                    this.isLoading = false;
                    this.spinnerService.hide();

                    this.saveHistory(this.id, titleHistory, messageHistory);

                    await this.alertService
                      .showResponseModal('success', res.data)
                      .pipe(
                        map((confirmed) => {
                          if (confirmed) {
                            this.goToMainManageAccessRequest();
                          }
                        })
                      )
                      .toPromise();
                  },
                  (err) => {
                    this.translateService
                      .get(`generic.toast.title.error`)
                      .subscribe((msg) => {
                        this.toast.showToast(ToastTypeEnum.DANGER, msg, err);
                        this.isLoading = false;
                        this.spinnerService.hide();
                      });
                  }
                );
              }
            })
          )
          .toPromise();
      });
  }

  goToMainManageAccessRequest(): void {
    this.router.navigate(['/gerenciar-pedidos-de-acesso']);
  }

  downloadArquivo(): void {
    const {
      company_bond_file,
      company_bond_file_extension,
      company_bond_file_name,
    } = this.requester;
    const blob = new Base64ToBlob().convertToBlob(
      company_bond_file,
      company_bond_file_extension
    );
    FileSaver.saveAs(blob, company_bond_file_name);
  }

  save(): void {
    this.registerForm.patchValue({
      id: this.id,
      cnpj: this.requester.cnpj,
      locale: localStorage.getItem('locale'),
      complement_data_id: this.requester.complement_data_id,
      bondLetter: this.accept,
      company_bond_file: this.base64File.split(',')[1],
      company_bond_file_name: this.fileName,
      company_bond_file_extension: '.' + this.fileExtension,
      company_bond_file_size: this.fileSize,
    });

    const data = this.registerForm.getRawValue();

    this.services.putData(`Access/additionalData`, data).subscribe((x) => {
      this.toast.showToast(
        ToastTypeEnum.INFO,
        'Sucesso',
        'Sucesso ao salvar os dados'
      );
      this.router.navigate(['/home']);
    });
  }

  removeFile(): void {
    this.selectedFile = null;
    this.fileName = '';
    this.fileSize = null;
    this.fileExtension = null;
    this.base64File = null;
    this.showBtn = true;
  }
  async fileToBase64(): Promise<void> {
    const reader = new FileReader();
    reader.readAsDataURL(this.selectedFile as Blob);
    reader.onloadend = async () => {
      this.base64File = (await reader.result) as string;
    };
  }

  async onFileSelected(event): Promise<void> {
    this.accept = true;
    this.selectedFile = <File>event.target.files[0];
    this.fileName = this.selectedFile.name;
    this.fileSize = this.selectedFile.size;
    this.fileExtension = this.selectedFile.name.substr(
      this.selectedFile.name.lastIndexOf('.') + 1
    );
    await this.fileToBase64();
    if (this.showBtn) {
      this.showBtn = false;
    }
    this.registerForm.controls.company_bond_file_name.setValue(this.fileName);

    if (!this.validateExtension(this.fileExtension)) {
      this.fileName = null;
      this.translateService
        .get('generic.invalid_file_type')
        .subscribe((msg) => (this.fileMessage = msg));
      this.toast.showToast(ToastTypeEnum.DANGER, 'Erro', this.fileMessage);
    }
  }

  validateExtension(extension: string): boolean {
    return (
      extension === 'pdf' ||
      extension === 'png' ||
      extension === 'jpeg' ||
      extension === 'jpg'
    );
  }
}
