<div class="row">
    <div class="col-sm-12">
        <blockquote class="blockquote text-left">
            <p class="mb-0" translate>manage_acccess_request.edit.history.component_name</p>
        </blockquote>

        <div *ngFor="let history of histories">
            <div class="card history__card">
                <ul class="list-group list-group-flush">
                    <li class="list-group-item history__card__body"></li>
                    <li class="list-group-item">
                        <strong translate>manage_acccess_request.edit.history.updated_by</strong>
                        <em> {{ history.issuingUser }} </em> ,
                        <strong translate>manage_acccess_request.edit.history.updated_in</strong>
                        {{ history.sendDate | date: 'dd/MM/yyyy HH:mm:ss' }}
                    </li>
                    <li class="list-group-item history__card__body">
                        <div class="container">

                            <h6>{{ history.title }}</h6>
                            <p>
                                {{ history.description }}
                            </p>
                        </div>
                    </li>
                </ul>
            </div>
            <br>
        </div>
        <br>
        <div class="card history_form">
            <form [formGroup]="historyForm">
                <div class="card-body">
                    <div>
                        <p class="h6" translate>manage_acccess_request.edit.history.title</p>
                        <input type="text" formControlName="title"
                            class="form-control form-control-sm col-lg-6 col-md-6">
                    </div>
                    <br>
                    <div>
                        <p class="h6" translate>manage_acccess_request.edit.history.message</p>
                        <textarea formControlName="description" class="form-control form-control-sm"></textarea>
                    </div>
                    <br>
                    <div class="text-right">
                        <a class="btn btn__default" (click)="reset()"
                            translate>manage_acccess_request.edit.history.clear</a>
                        &nbsp;
                        <a class="btn btn__primary" (click)="submit()"
                            translate>manage_acccess_request.edit.history.send</a>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <hr>
</div>
<br>