import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Validators } from 'ngx-editor';
import { CommonServices } from 'src/app/core/services/common.services';
import { ToastService } from 'src/app/core/services/toast.service';
import { ToastTypeEnum } from 'src/app/shared/enums/toast-type-enum';

@Component({
  selector: 'rastreabilidade-user-history',
  templateUrl: './user-history.component.html',
  styleUrls: ['./user-history.component.css']
})
export class UserHistoryComponent implements OnInit {

  @Input() public requestId = null;
  histories: any[] = [];
  mySubscription: any;

  historyForm = this.fb.group({
    requestId: [null, Validators.required],
    title: [null, [Validators.required]],
    description: [null, Validators.required]
  });

  constructor(
    private services: CommonServices,
    private fb: FormBuilder,
    private toast: ToastService
  ) { }

  ngOnInit() {
    // buscar e carregar dados do histórico
    this.loadHistory();

    this.historyForm.controls.requestId.setValue(this.requestId);
  }

  loadHistory() {
    this.services.get(`Historic/historyForUser/${this.requestId}`).subscribe(res => {
      this.histories = res.data;
    });
  }

  reset() {
    this.historyForm.reset();
  }

  submit() {
    let form = this.historyForm.value;
    this.services.post(`Historic/SaveHistoryForUser/${this.requestId}`, form).subscribe(res => {
      this.loadHistory();
      this.reset();
      this.toast.showToast(ToastTypeEnum.SUCCESS, 'Sucesso', 'Sucesso ao salvar mensagem.');
    });
  }

}

