<ng-template #modal>
  <div class="modal-content">
    <div class="modal-header" *ngIf="modalConfig?.modalTitle">
      <h5 class="modal-title">{{ modalConfig?.modalTitle }}</h5>
    </div>
    <div class="modal-body">
      <ng-content></ng-content>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-{{modalConfig.colorCloseButton ? modalConfig.colorCloseButton : 'light'}}"
        (click)="close()" *ngIf="modalConfig.hideCloseButton === undefined || !modalConfig.hideCloseButton()"
        [disabled]="modalConfig.disableCloseButton !== undefined && modalConfig.disableCloseButton()">
        {{ modalConfig?.closeButtonLabel ? modalConfig?.closeButtonLabel : "Cancelar" }}
      </button>
      <button type="button" class="btn btn-{{modalConfig.colorDenyButton ? modalConfig.colorDenyButton : 'danger'}}"
        (click)="deny()" *ngIf="modalConfig.hideDenyButton === false || modalConfig.hideDenyButton !== undefined"
        [disabled]="modalConfig.disableDenyButton">
        {{ modalConfig?.denyButtonLabel ? modalConfig?.denyButtonLabel : "Revogar" }}
      </button>
      <button type="button" class="btn btn-{{modalConfig.colorSaveButton ? modalConfig.colorSaveButton : 'primary'}}"
        (click)="save()" *ngIf="modalConfig.hideSaveButton === false || modalConfig.hideSaveButton !== undefined"
        [disabled]="modalConfig.disableSaveButton">
        {{ modalConfig?.saveButtonLabel ? modalConfig?.saveButtonLabel : "Salvar" }}
      </button>
    </div>
  </div>
</ng-template>
