import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { Router } from "@angular/router";
import * as Sentry from '@sentry/angular';
import { NgbDateParserFormatter, NgbModule, NgbPagination, NgbTypeaheadConfig } from '@ng-bootstrap/ng-bootstrap';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular'
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import {
  faBan,
  faBolt,
  faCheckCircle,
  faCircle,
  faCloudDownloadAlt,
  faExclamationTriangle,
  faExternalLinkAlt,
  faFile,
  faFileDownload,
  faFilePdf,
  faGlobe,
  faInfoCircle,
  faPaperclip,
  faPaperPlane,
  faPenSquare,
  faSearch,
  faShare,
  faSyncAlt,
  faTachometerAlt,
  faTimes,
  faTimesCircle,
  faEdit,
  faTrash,
  faFileCsv,
  faUser,
  faTrashAlt,
  faFileUpload,
  faUpload,
  faArrowLeft,
  faQrcode,
  faBarcode,
  faAngleRight,
  faCalendarAlt,
  faLink
} from '@fortawesome/free-solid-svg-icons';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from './core/core.module';
import { AuthModule } from './modules/auth/auth.module';
import { initializeKeycloak } from './configurations/app.init';
import { JwtInterceptor } from './core/interceptors/jwt-interceptor';
import { SpinnerService } from './core/services/spinner.service';
import { ConfirmModalComponent } from './shared/components/confirm-modal/confirm-modal.component';
import { AlertModalComponent } from './shared/components/alert-modal/alert-modal.component';
import { SharedModule } from './shared/shared.module';
import { ToastService } from './core/services/toast.service';
import { AlertModalService } from './core/services/alert-modal.service';
import { ErrorInterceptorProvider } from './core/interceptors/error.interceptor';
import { ManageAccessRequestModule } from './modules/manage-access-request/manage-access-request.module';
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { ConsultByBaleModule } from './modules/search-by-bale/search-by-bale.module';
import { BatchSearchModule } from './modules/batch-search/batch-search.module';
import { ModalModule } from 'ngx-bootstrap/modal';
import { CertificateConfigurationModule } from './modules/certificate-configuration/certificate-configuration.module';
import { MonitoringConfigurationModule } from './modules/monitoring-configuration/monitoring-configuration.module';
import { ReportsModule } from './modules/reports/reports.module';
import { HttpService } from './core/services/http.service';
import { BrowserModule } from '@angular/platform-browser';
import { TranslateConfigService } from './core/services/translate-config.service';


export function rootLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json')
}

import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';
import { CustomDateParserFormatter } from './shared/utils/customDateParserFormatter';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BsDatepickerConfig, BsDatepickerModule, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { AlertModule, AlertConfig } from "ngx-bootstrap/alert";

import { defineLocale } from 'ngx-bootstrap/chronos';
import { ptBrLocale } from 'ngx-bootstrap/locale';
 defineLocale('pt', ptBrLocale);

registerLocaleData(localePt);

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    NgbModule,
    HttpClientModule,
    AppRoutingModule,
    AuthModule,
    KeycloakAngularModule,
    FontAwesomeModule,
    SharedModule,
    ManageAccessRequestModule,
    ConsultByBaleModule,
    BatchSearchModule,
    CertificateConfigurationModule,
    MonitoringConfigurationModule,
    ReportsModule,
    CoreModule,
    ModalModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: rootLoaderFactory,
        deps: [HttpClient]
      }
    }),
    BrowserModule,
    BrowserAnimationsModule,
    AlertModule,
    BsDatepickerModule.forRoot()
  ],
  providers: [
    SpinnerService,
    HttpService,
    AlertModalService,
    ToastService,
    {
      provide: Sentry.TraceService,
      deps: [Router]
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService]
    },
    ErrorInterceptorProvider,
    {
      provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true
    },
    TranslateConfigService,
    {
      provide: LOCALE_ID,
      deps: [TranslateConfigService],
      useFactory: (translate) => translate.getLocale()
    },
    {
      provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter
    },
    BsDatepickerConfig,
    AlertConfig,
  ],
  bootstrap: [AppComponent],
  entryComponents: [NgbPagination, ConfirmModalComponent, AlertModalComponent]
})
export class AppModule {
  constructor(library: FaIconLibrary) {
    // Add an icon to the library for convenient access in other components
    library.addIcons(faSearch);
    library.addIcons(faSyncAlt);
    library.addIcons(faInfoCircle);
    library.addIcons(faExternalLinkAlt);
    library.addIcons(faCloudDownloadAlt);
    library.addIcons(faFileDownload);
    library.addIcons(faFilePdf);
    library.addIcons(faExclamationTriangle);
    library.addIcons(faFile);
    library.addIcons(faPaperclip);
    library.addIcons(faCheckCircle);
    library.addIcons(faPaperPlane);
    library.addIcons(faTimesCircle);
    library.addIcons(faTimes);
    library.addIcons(faPenSquare);
    library.addIcons(faTachometerAlt);
    library.addIcons(faBolt);
    library.addIcons(faShare);
    library.addIcons(faBan);
    library.addIcons(faGlobe);
    library.addIcons(faUser);
    library.addIcons(faCircle);
    library.addIcons(faEdit);
    library.addIcons(faTrash);
    library.addIcons(faTrashAlt);
    library.addIcons(faFileCsv);
    library.addIcons(faUpload);
    library.addIcons(faArrowLeft);
    library.addIcons(faQrcode);
    library.addIcons(faBarcode);
    library.addIcons(faAngleRight)
    library.addIcons(faCalendarAlt);
    library.addIcons(faLink);
  }
}
