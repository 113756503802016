import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CommonServices } from 'src/app/core/services/common.services';
import { ToastService } from 'src/app/core/services/toast.service';
import { ToastTypeEnum } from 'src/app/shared/enums/toast-type-enum';

@Component({
  selector: 'rastreabilidade-request-access',
  templateUrl: './request-access.component.html',
  styleUrls: ['./request-access.component.scss']
})
export class RequestAccessComponent implements OnInit {

  placeholderEmailExample: string;

  requestAccessForm = this.fb.group({
    email: [null, [Validators.required, Validators.email]]
  })

  constructor(
    private fb: FormBuilder,
    private services: CommonServices,
    private toast: ToastService,
    private router: Router,
    private translateService: TranslateService,
  ) { }

  ngOnInit(): void {
    this.getPlaceholder();
  }

  enviar() {
    this.services.post(`Access/requestAccess/${this.requestAccessForm.controls.email.value}`, this.requestAccessForm.controls.email.value).subscribe(x => {
      this.translateService.get('generic.toast.title.warn').subscribe(msg => {
        this.toast.showToast(ToastTypeEnum.INFO, msg, x.data)
        this.router.navigate(['/'])
      });
    }, err => {
      this.translateService.get('generic.toast.title.error').subscribe(msg => {
        this.toast.showToast(ToastTypeEnum.DANGER, msg, err);
      });
    })
  }

  getPlaceholder() {
    this.translateService.onLangChange.subscribe(() => {
      this.translateService.get('register.placeholder_email_example').subscribe(msg => {
        this.placeholderEmailExample = msg;
      });
    });

    this.translateService.get('register.placeholder_email_example').subscribe(msg => {
      this.placeholderEmailExample = msg;
    });
  }

}
