import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { HttpService } from 'src/app/core/services/http.service';
import { SpinnerService } from 'src/app/core/services/spinner.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { ToastTypeEnum } from 'src/app/shared/enums/toast-type-enum';

@Component({
  selector: 'rastreabilidade-history-form-card',
  templateUrl: './history-form-card.component.html',
  styleUrls: ['./history-form-card.component.scss']
})
export class HistoryFormCardComponent implements OnInit {

  @Output() eventEmitter = new EventEmitter<any>();
  @Input() public requestId = null;
  reload: boolean = true;

  historyForm = this.fb.group({
    requestId: [null, Validators.required],
    title: [null, [Validators.required]],
    description: [null, Validators.required]
  });

  constructor(
    private fb: FormBuilder, 
    private services: HttpService, 
    private toast: ToastService,
    private spinner: SpinnerService) { }

  ngOnInit(): void { 
    this.setRequestId();
  }

  setRequestId() {
    this.historyForm.controls.requestId.setValue(this.requestId);
  }

  submit() {
    let form = this.historyForm.value;
    this.spinner.show()
    this.services.post(`Historic/saveHistory/${this.requestId}`, form).subscribe(res => {
      this.eventEmitter.emit(this.reload);
      this.reset()
      this.toast.showToast(ToastTypeEnum.SUCCESS, 'Sucesso', 'Sucesso ao salvar mensagem.');
      this.spinner.hide();
    },
    (err) => { 
      this.toast.showToast(ToastTypeEnum.DANGER, 'Erro', `${err}`);
      this.spinner.hide();
    });
  }

  reset() {
    this.historyForm.reset();
    this.setRequestId();
  }


}
