<div class="header">
  <nav class="navbar navbar-light bg-primary">
    <div class="container justify-content-between">
      <a class="navbar-brand" [routerLink]="'home'">
        <img src="../assets/images/logo_abrapa_branco.svg" alt="ABRAPA-ABR" class="logo-abrapa" />
        <img *ngIf="languageSelected == 'pt' " src="../assets/images/logo_sai_branco.svg" alt="SAI" class="logo-sai" />
        <img *ngIf="languageSelected == 'en' " src="../assets/images/logo_sai_branco_en.svg" alt="SAI" class="logo-sai" />
      </a>
      <div class="authorized" *ngIf="authorized">
        <div class="dropdown">
          <a class="dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" id="language">
            <fa-icon [icon]="['fas', 'globe']"></fa-icon> {{ getLanguage() }}
          </a>
          <ul class="dropdown-menu" aria-labelledby="language">
            <li><a class="dropdown-item" (click)="changeLang('pt')" translate>monitoring.form.labels.portuguese</a></li>
            <li><a class="dropdown-item" (click)="changeLang('en')" translate>monitoring.form.labels.english</a></li>
          </ul>
        </div>
        <div class="dropdown">
          <a class="dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" id="user">
            <fa-icon [icon]="['fas', 'user']"></fa-icon> {{'header.greetings' | translate}}, {{ service.loginUsuario }}
          </a>
          <ul class="dropdown-menu" aria-labelledby="user">
            <li *ngIf="isSolicitante" [routerLink]="['editar-solicitante', accessRequestId]"><a class="dropdown-item" translate>header.perfil</a>
            <li (click)="service.logout()"><a class="dropdown-item" translate>header.logout</a>   
            </li>
          </ul>
        </div>
      </div>
    </div>
  </nav>
  <nav class="navbar navbar-light secondary">
    <div class="container">
      <div class="navbar-collapse" id="navbarText">
        <ul class="nav mr-auto nav__menu navbar-nav">
          <ng-container *ngIf="isAbrapa">
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                id="administrative" translate>header.administrative</a>
              <ul class="dropdown-menu" aria-labelledby="administrative">
                <li><a class="dropdown-item" [routerLink]="['configuracao-certificado']"
                    translate>header.certificate_configuration</a></li>
                <li><a class="dropdown-item" [routerLink]="['configuracao-monitoramento']"
                    translate>header.monitoring_configuration</a></li>
              </ul>
            </li>
            <li class="nav-item">
              <a [routerLink]="['gerenciar-pedidos-de-acesso']" class="nav-link"
                translate>header.applicant_accreditation</a>
            </li>
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" id="navbarDropdownMenuLink" data-toggle="dropdown"
                aria-haspopup="true" aria-expanded="false" translate>header.reports</a>
              <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                <li class="dropdown-submenu">
                  <a class="dropdown-item dropdown-toggle" translate>
                    header.producer_trading
                  </a>
                  <ul class="dropdown-menu">
                    <li><a class="dropdown-item drop sub__menu" [routerLink]="['relatorios/produtor-solicitante']"
                        translate>header.producer_applicant</a></li>
                    <li><a class="dropdown-item drop sub__menu" [routerLink]="['relatorios/trading-solicitante']"
                        translate>header.trading_applicant</a></li>
                  </ul>
                </li>
                <li class="dropdown-submenu">
                  <a class="dropdown-item dropdown-toggle" translate>header.accreditation</a>
                  <ul class="dropdown-menu">
                    <li><a class="dropdown-item drop sub__menu" [routerLink]="['relatorios/status-credenciamento']"
                        translate>header.request_status</a></li>
                    <li><a class="dropdown-item drop sub__menu" [routerLink]="['relatorios/tempo-medio']"
                        translate>header.average_time</a></li>
                  </ul>
                </li>
              </ul>
            </li>
          </ng-container>
          <ng-container *ngIf="isSolicitante">
            <li class="nav-item dropdown menu__itens">
              <a [routerLink]="['consulta-fardo-a-fardo']" class="nav-link main__item"
                translate>header.search_by_bale</a>
            </li>
            <li class="nav-item dropdown menu__itens">
              <a [routerLink]="['consulta-por-lote']" class="nav-link main__item" translate>header.batch_search</a>
            </li>
            <li class="nav-item dropdown menu__itens">
              <a [routerLink]="['relatorios/solicitante']" class="nav-link main__item" translate>header.reports</a>
            </li>
          </ng-container>
        </ul>
      </div>
    </div>
  </nav>
</div>